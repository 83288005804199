import React from 'react';
import {
  Typography,
  Card,
  CardContent,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

function NotificationCard() {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {t('title.notification')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - 「バグ報告・ご要望」の機能を追加しました。
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - BNBチェーンに対応しました。画面右上の設定から変更できます。
        </Typography>
        <Typography variant="body2" color="text.secondary">
          2022/5/29 9:30 JST
        </Typography>
      </CardContent>
    </Card>
  );
}

export default NotificationCard;
