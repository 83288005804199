import React from 'react';
import {
  Typography,
  Avatar,
  AppBar,
  Toolbar,
  Drawer,
  Button,
  Box,
  Divider,
  ListItemButton,
  List,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

function Header(
  {
    lang,
    baseCurrency,
    setBaseCurrency,
    chain,
    setChain,
  }: {
  lang: string;
  baseCurrency: 'JPY' | 'USD';
  setBaseCurrency: Function;
  chain: 'sol' | 'bnb';
  setChain: Function
},
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDrawing, setIsDrawing] = React.useState(false);
  const setLanguage = (language: string) => {
    localStorage.setItem('lang', language);
    if (language === 'ja') {
      navigate('/');
    } else {
      navigate(`/${language}`);
    }
    setIsDrawing(false);
  };
  const setBaseCurrencyWrapper = (currency: 'JPY' | 'USD') => {
    setBaseCurrency(currency);
    localStorage.setItem('baseCurrency', currency);
    setIsDrawing(false);
  };

  const setChainWrapper = (c: 'sol' | 'bnb') => {
    setChain(c);
    localStorage.setItem('chain', c);
    setIsDrawing(false);
  };

  return (
    <AppBar position="static" color="default" elevation={1} sx={{ zIndex: 100, position: 'relative' }}>
      <Container maxWidth="xs">
        <Toolbar disableGutters>
          <Avatar src={`${process.env.PUBLIC_URL}/logo_stepn.png`} alt="Logo" sx={{ width: 48, height: 48, marginRight: 2 }} />
          <Typography component="h1" variant="h5" sx={{ flexGrow: 1 }}>
            {`STEPN CHART (${chain.toUpperCase()})`}
          </Typography>
          <Button onClick={() => setIsDrawing(true)}>
            <SettingsIcon color="action" />
          </Button>
          <Drawer
            anchor="right"
            open={isDrawing}
            onClose={() => setIsDrawing(false)}
          >
            <Box sx={{ display: 'flex', padding: '16px', width: '320px' }}>
              <Typography component="div" sx={{ flexGrow: 1 }}>
                {t('title.settings')}
              </Typography>
              <Typography>
                <CloseIcon onClick={() => setIsDrawing(false)} />
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: '16px' }}>
              <Typography gutterBottom variant="body1" component="p">
                Language
              </Typography>
              <List>
                <ListItemButton selected={lang !== 'en'} divider>
                  <Button onClick={() => setLanguage('ja')} sx={{ width: '100%' }}>
                    日本語
                  </Button>
                </ListItemButton>
                <ListItemButton selected={lang === 'en'}>
                  <Button onClick={() => setLanguage('en')} sx={{ width: '100%' }}>
                    English
                  </Button>
                </ListItemButton>
              </List>
              <Typography gutterBottom variant="body1" component="p">
                DefaultCurrency
              </Typography>
              <List>
                <ListItemButton divider selected={baseCurrency === 'JPY'}>
                  <Button onClick={() => setBaseCurrencyWrapper('JPY')} sx={{ width: '100%' }}>
                    JPY（¥）
                  </Button>
                </ListItemButton>
                <ListItemButton selected={baseCurrency === 'USD'}>
                  <Button onClick={() => setBaseCurrencyWrapper('USD')} sx={{ width: '100%' }}>
                    USD（$）
                  </Button>
                </ListItemButton>
              </List>
              <Typography gutterBottom variant="body1" component="p">
                Chain
              </Typography>
              <List>
                <ListItemButton divider selected={chain === 'sol'}>
                  <Button onClick={() => setChainWrapper('sol')} sx={{ width: '100%' }}>
                    SOL
                  </Button>
                </ListItemButton>
                <ListItemButton selected={chain === 'bnb'}>
                  <Button onClick={() => setChainWrapper('bnb')} sx={{ width: '100%' }}>
                    BNB
                  </Button>
                </ListItemButton>
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
