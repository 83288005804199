export type Currencies = {
  [key: string]: {
    priceYen: number;
  };
}

export const currencyNames = ['GST', 'GMT', 'SOL', 'USDC', 'JPY'];
export const currencyNamesWithUSD = ['GST', 'GMT', 'SOL', 'USD'];

export const getCurrencyNames = (baseCurrency: 'JPY' | 'USD', chain: 'sol' | 'bnb'): string[] => {
  if (baseCurrency === 'JPY' && chain === 'sol') {
    return ['GST', 'GMT', 'SOL', 'USDC', 'JPY'];
  }
  if (baseCurrency === 'USD' && chain === 'sol') {
    return ['GST', 'GMT', 'SOL', 'USDC'];
  }
  if (baseCurrency === 'JPY' && chain === 'bnb') {
    return ['bGST', 'GMT', 'BNB', 'JPY'];
  }
  return ['bGST', 'GMT', 'BNB', 'USDC'];
};

export const roundOff = (num: number) => Math.round(num * 100) / 100;

export const calculatePrice = (
  ammount: number,
  currency: string,
  currencies: Currencies,
  baseCurrency: string,
) => {
  if (currency === baseCurrency) {
    // ここは通らないはず
    return ammount;
  }
  if (baseCurrency === 'JPY') {
    return roundOff(ammount * currencies[currency].priceYen);
  }
  return roundOff((ammount * currencies[currency].priceYen) / currencies.USDC.priceYen);
};

export const dispName = (name: string, baseCurrency: 'JPY' | 'USD') => {
  if (name === 'USDC' && baseCurrency === 'USD') {
    return 'USD';
  }
  return name;
};
