import React from 'react';
import {
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function TweetEmbed() {
  const { t } = useTranslation();
  return (
    <Card sx={{ minHeight: '360px' }}>
      <CardContent>
        <Typography component="h2" variant="h6">
          {t('title.officialTweet')}
        </Typography>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="Stepnofficial"
          noFooter
          noHeader
          noBorders
          options={{ height: 350, width: '100%' }}
        />
      </CardContent>
    </Card>
  );
}

export default TweetEmbed;
