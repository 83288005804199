import React from 'react';
import {
  Box,
  Link,
  Container,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';

import Copyright from './Copyright';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Link href="https://twitter.com/M0rrisFreeman">
          <TwitterIcon />
        </Link>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
