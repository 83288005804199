import React, { useEffect, useState } from 'react';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ExpectedEarnings from './ExpectedEarnings';

import { Currencies, calculatePrice } from '../models/currencies';

function ExpectedEarningsCard({
  currencies,
  baseCurrency,
  chain,
} : {
  currencies: Currencies;
  baseCurrency: 'JPY' | 'USD';
  chain: 'sol' | 'bnb';
}) {
  const baseChain = chain === 'sol' ? 'GST' : 'bGST';
  const { t } = useTranslation();
  const [earningGST, setEarningGST] = useState('');
  const [earnAmmount, setEarnAmmount] = useState(0);
  const plaseholder = `${baseChain} / ${t('elements.day')}`;
  useEffect(() => {
    // reset earn ammount
    setEarningGST('');
  }, [chain]);
  useEffect(() => {
    setEarnAmmount(calculatePrice(Number(earningGST), baseChain, currencies, baseCurrency));
  }, [earningGST, currencies, baseCurrency]);
  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h6">
          {t('title.profitCalculation')}
        </Typography>
        <TextField
          size="small"
          fullWidth
          type="number"
          value={earningGST}
          onChange={(e) => setEarningGST(e.target.value)}
          placeholder={plaseholder}
          InputProps={{
            endAdornment: <InputAdornment position="end">{plaseholder}</InputAdornment>,
          }}
        />
        <ExpectedEarnings
          earnAmmount={earnAmmount}
          baseCurrency={baseCurrency}
        />
      </CardContent>
    </Card>
  );
}

export default ExpectedEarningsCard;
