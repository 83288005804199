import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import {
  Currencies, getCurrencyNames, roundOff, dispName,
} from '../models/currencies';

function CurrencyConversionList({
  currencies,
  price,
  baseCurrency,
  chain,
}: {
  currencies: Currencies;
  price: number;
  baseCurrency: 'JPY' | 'USD';
  chain: 'sol' | 'bnb';
}) {
  const currencyNames = getCurrencyNames(baseCurrency, chain);
  return (
    <List>
      {currencyNames.map((name) => {
        if (name === 'JPY') {
          return (
            <ListItem key={name}>
              <ListItemText secondary={`${roundOff(price)} JPY`} />
            </ListItem>
          );
        }
        return (
          <ListItem key={name}>
            <ListItemText
              secondary={`${roundOff(price / (currencies[name].priceYen))} ${dispName(name, baseCurrency)}`}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default CurrencyConversionList;
