import React from 'react';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';

import { db } from './firebase';
import {
  Currencies, calculatePrice, getCurrencyNames,
} from '../models/currencies';
import BlinkingListItemText from './BlinkingListItemText';

function CurrentPriceList({
  currencies,
  setCurrencies,
  blinkText,
  isBlinking,
  baseCurrency,
  chain,
}: {
  currencies: Currencies;
  setCurrencies: Function;
  blinkText: () => void;
  isBlinking: boolean;
  baseCurrency: 'JPY' | 'USD';
  chain: 'sol' | 'bnb';
}) {
  const currencyNames = getCurrencyNames(baseCurrency, chain);
  const { t } = useTranslation();
  const reloadCurrencies = async () => {
    blinkText();
    const currenciesCollectionRef = collection(db, 'currencies');
    const snapshot = await getDocs(query(currenciesCollectionRef, orderBy('createdAt', 'desc'), limit(1)));
    const data = snapshot.docs[0].data() as Currencies;
    delete data.createdAt;
    setCurrencies(data);
  };
  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h6" component="div">
            {t('title.rate')}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            <IconButton
              onClick={() => reloadCurrencies()}
            >
              <CachedIcon />
            </IconButton>
          </Typography>
        </Box>
        <List>
          {currencyNames.map((name) => {
            if (name === 'JPY' || (baseCurrency === 'USD' && name === 'USDC')) {
              return null;
            }
            return (
              <ListItem key={name} disablePadding>
                <ListItemIcon>
                  <Avatar src={`${process.env.PUBLIC_URL}/icon_${name.toLowerCase()}.png`} alt="Logo" sx={{ width: 32, height: 32 }} />
                </ListItemIcon>
                <BlinkingListItemText primary={name} secondary={`${calculatePrice(1, name, currencies, baseCurrency)} ${baseCurrency}`} isBlinking={isBlinking} />
              </ListItem>
            );
          })}
        </List>
        <Typography gutterBottom variant="body2" color="text.secondary">
          {t('messages.updateInterval')}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CurrentPriceList;
