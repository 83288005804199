import React, { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import {
  CssBaseline,
  Container,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../components/firebase';

import CurrentPriceList from '../components/CurrentPriceList';
// import MintPrice from '../components/MintPrice';
import Donation from '../components/Donation';
import TweetEmbed from '../components/TweetEmbed';
import Footer from '../components/Footer';
import ExpectedEarningsCard from '../components/ExpectedEarningsCard';
import LevelUpCostCard from '../components/LevelUpCostCard';

import { Currencies } from '../models/currencies';
import MultiCurrencyConversionCard from '../components/MultiCurrencyConversionCard';
import Header from '../components/Header';
import NotificationCard from '../components/NotificationCard';
import ReportsCard from '../components/ReportsCard';

function TopPage() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const [currencies, setCurrensies] = useState<Currencies>();
  const [isBlinking, setIsBlinking] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState<'JPY' | 'USD'>('JPY');
  const [chain, setChain] = useState<'sol' | 'bnb'>('sol');
  const navigate = useNavigate();
  useEffect(() => {
    // fetch data
    const f = async () => {
      const currenciesCollectionRef = collection(db, 'currencies');
      const snapshot = await getDocs(query(currenciesCollectionRef, orderBy('createdAt', 'desc'), limit(1)));
      const data = snapshot.docs[0].data() as Currencies;
      delete data.createdAt;
      setCurrensies(data);
    };
    f();
  }, []);
  useEffect(() => {
    // routing
    const localLang = localStorage.getItem('lang');
    if (lang !== 'en' && localLang === 'en') {
      navigate('/en');
    } else if (lang !== undefined && lang !== 'en' && localLang !== 'en') {
      navigate('/');
    }
  }, [lang, navigate]);
  useEffect(() => {
    // set lang
    const localLang = localStorage.getItem('lang');
    if (lang === 'en') {
      localStorage.setItem('lang', 'en');
      i18n.changeLanguage('en');
    } else if (localLang === null) {
      localStorage.setItem('lang', 'ja');
      i18n.changeLanguage('ja');
    } else {
      i18n.changeLanguage('ja');
    }
  }, [lang, i18n]);
  useEffect(() => {
    // set baseCurrency
    const localBaseCurrency = localStorage.getItem('baseCurrency');
    if (localBaseCurrency !== null && (localBaseCurrency === 'JPY' || localBaseCurrency === 'USD')) {
      setBaseCurrency(localBaseCurrency);
    } else if (lang === 'en') {
      localStorage.setItem('baseCurrency', 'USD');
      setBaseCurrency('USD');
    } else {
      localStorage.setItem('baseCurrency', 'JPY');
      setBaseCurrency('JPY');
    }
  }, [lang]);
  useEffect(() => {
    // set chain
    const localChain = localStorage.getItem('chain');
    if (localChain === null) {
      localStorage.setItem('chain', 'sol');
      setChain('sol');
    } else if (localChain === 'sol' || localChain === 'bnb') {
      setChain(localChain);
    }
  }, []);

  const blinkText = () => {
    setIsBlinking(true);
    setTimeout(() => { setIsBlinking(false); }, 300);
  };

  if (currencies === undefined) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%',
          marginTop: 3,
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Header
        lang={lang || 'ja'}
        baseCurrency={baseCurrency}
        setBaseCurrency={setBaseCurrency}
        chain={chain}
        setChain={setChain}
      />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%',
          alignItems: 'center',
          backgroundColor: '#fafafa',
          padding: '16px',
        }}
      >
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NotificationCard />
          </Grid>
          <Grid item xs={12}>
            <CurrentPriceList
              currencies={currencies}
              setCurrencies={setCurrensies}
              blinkText={blinkText}
              isBlinking={isBlinking}
              baseCurrency={baseCurrency}
              chain={chain}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiCurrencyConversionCard
              currencies={currencies}
              baseCurrency={baseCurrency}
              chain={chain}
            />
          </Grid>
          <Grid item xs={12}>
            <LevelUpCostCard
              currencies={currencies}
              baseCurrency={baseCurrency}
              chain={chain}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <MintPrice
              currencies={currencies}
              isBlinking={isBlinking}
              baseCurrency={baseCurrency}
            />
          </Grid> */}
          <Grid item xs={12}>
            <ExpectedEarningsCard
              currencies={currencies}
              baseCurrency={baseCurrency}
              chain={chain}
            />
          </Grid>
          <Grid item xs={12}>
            <TweetEmbed />
          </Grid>
          <Grid item xs={12}>
            <ReportsCard />
          </Grid>
          <Grid item xs={12}>
            <Donation />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  );
}

export default TopPage;
