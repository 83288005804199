import { initializeApp } from 'firebase/app';
// import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyC8P-itvazUg3Vuy04F1cVEUFaUCMDpIp4',
  authDomain: 'stepn-calc.firebaseapp.com',
  projectId: 'stepn-calc',
  storageBucket: 'stepn-calc.appspot.com',
  messagingSenderId: '850196929605',
  appId: '1:850196929605:web:c576b45ee80293cc65d72c',
  measurementId: 'G-0G1WPHT5GH',
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();
// const analytics = getAnalytics(app);
// logEvent(analytics, 'notification_received');

export default app;
