import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Snackbar,
  Button,
  TextField,
} from '@mui/material';
import {
  collection,
  addDoc,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db } from '../components/firebase';

function ReportsCard() {
  const { t } = useTranslation();
  const [report, setReport] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleClickButton = (): void => {
    const f = async () => {
      const ref = collection(db, 'reports');
      await addDoc(ref, {
        text: report,
        confirmed: false,
        createdAt: new Date(),
      });
      setReport('');
    };
    f();
    setOpen(true);
  };
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {t('title.report')}
        </Typography>
        <TextField
          id="standard-basic"
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          value={report}
          onChange={(e) => setReport(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickButton}
          sx={{ marginTop: '1rem', width: '100%' }}
          disabled={report === ''}
        >
          {t('elements.send')}
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="sended!"
        />
      </CardContent>
    </Card>
  );
}

export default ReportsCard;
