import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Card,
  CardContent,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// import PriceList from './PriceList';

import { Currencies, dispName, getCurrencyNames } from '../models/currencies';
import CurrencyConversionList from './CurrencyConversionList';

type AmmountList = {
  [currencyName: string]: number;
}

function MultiCurrencyConversionCard({
  currencies,
  baseCurrency,
  chain,
} : {
  currencies : Currencies,
  baseCurrency : 'JPY' | 'USD',
  chain : 'sol' | 'bnb',
}) {
  const currencyNames = getCurrencyNames(baseCurrency, chain);
  const { t } = useTranslation();
  const [inputAmmountList, setInputAmmountList] = useState<AmmountList>({});
  const [selectedCurrencies, setSelectedCurrencies] = useState([chain === 'sol' ? 'GST' : 'bGST']);
  const [yenAmmount, setYenAmmount] = useState(0);
  const handleChangeSelectedCurrencies = (
    event: React.MouseEvent<HTMLElement>,
    newCurrencies: string[],
  ) => {
    setSelectedCurrencies(newCurrencies.length === 0 ? ['GST'] : newCurrencies);
  };
  const handleChangeAmmount = (
    currencyName: string,
  ) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAmmountList = { ...inputAmmountList, [currencyName]: Number(event.target.value) };
    setInputAmmountList(newAmmountList);
  };
  useEffect(() => {
    const newYenAmmount = selectedCurrencies.map((currencyName) => {
      if (inputAmmountList[currencyName] === undefined) {
        return 0;
      }
      if (currencyName === 'JPY') {
        return inputAmmountList[currencyName];
      }
      return inputAmmountList[currencyName] * currencies[currencyName].priceYen;
    }).reduce((acc, curr) => acc + curr, 0);
    setYenAmmount(newYenAmmount);
  }, [inputAmmountList, currencies, selectedCurrencies]);
  useEffect(() => {
    // reset selected currencies
    setSelectedCurrencies([chain === 'sol' ? 'GST' : 'bGST']);
    setInputAmmountList({});
  }, [chain, baseCurrency]);

  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h6">
          {t('title.currencyConversion')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {t('messages.multipleSelectionsPossible')}
        </Typography>
        <Box>
          <ToggleButtonGroup
            fullWidth
            size="small"
            color="primary"
            value={selectedCurrencies}
            onChange={handleChangeSelectedCurrencies}
            sx={{
              marginBottom: 0.5,
            }}
          >
            {currencyNames.map((name) => (
              <ToggleButton key={name} value={name}>
                {dispName(name, baseCurrency)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        {selectedCurrencies.map((currencyName) => (
          <Box key={currencyName}>
            <TextField
              size="small"
              fullWidth
              type="number"
              value={inputAmmountList[currencyName] || ''}
              onChange={handleChangeAmmount(currencyName)}
              placeholder={dispName(currencyName, baseCurrency)}
              sx={{
                marginBottom: 0.5,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{dispName(currencyName, baseCurrency)}</InputAdornment>,
              }}
            />
          </Box>
        ))}
        <CurrencyConversionList
          currencies={currencies}
          price={yenAmmount}
          baseCurrency={baseCurrency}
          chain={chain}
        />
      </CardContent>
    </Card>
  );
}

export default MultiCurrencyConversionCard;
