import React from 'react';
import { ListItemText } from '@mui/material';

function BlinkingListItemText({
  primary,
  secondary,
  isBlinking,
}: {
  primary: string,
  secondary: string,
  isBlinking: boolean
}) {
  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      sx={{
        transition: '0.5s',
        opacity: isBlinking ? 0 : 1,
      }}
    />
  );
}

export default BlinkingListItemText;
