import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { roundOff } from '../models/currencies';

function ExpectedEarnings({
  earnAmmount,
  baseCurrency,
}:
  {
    earnAmmount: number;
    baseCurrency: 'JPY' | 'USD';
  }) {
  const { t } = useTranslation();
  return (
    <List>
      <ListItem>
        <ListItemText primary={`1${t('elements.day')}`} secondary={`${roundOff(earnAmmount)} ${baseCurrency}`} />
      </ListItem>
      <ListItem>
        <ListItemText primary={`1${t('elements.week')}`} secondary={`${roundOff(earnAmmount * 7)} ${baseCurrency}`} />
      </ListItem>
      <ListItem>
        <ListItemText primary={`1${t('elements.month')}`} secondary={`${roundOff(earnAmmount * 30)} ${baseCurrency}`} />
      </ListItem>
      <ListItem>
        <ListItemText primary={`1${t('elements.year')}`} secondary={`${roundOff(earnAmmount * 365)} ${baseCurrency}`} />
      </ListItem>
    </List>
  );
}

export default ExpectedEarnings;
