import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  OutlinedInput,
  InputAdornment,
  Snackbar,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CopyToClipBoard from 'react-copy-to-clipboard';

function Donation() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleClickButton = (): void => {
    setOpen(true);
  };
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {t('title.donation')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('messages.pleaseDonate')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`* ${t('messages.stepnWalletAddress')}`}
        </Typography>
        <OutlinedInput
          size="small"
          fullWidth
          disabled
          type="text"
          value="FNyhzvaELvBaVeN4TVR4EaHqWpmVoqD2MExp2y11HbS7"
          endAdornment={(
            <InputAdornment position="end">
              <CopyToClipBoard text="FNyhzvaELvBaVeN4TVR4EaHqWpmVoqD2MExp2y11HbS7">
                <IconButton
                  onClick={handleClickButton}
                >
                  <AssignmentIcon />
                </IconButton>
              </CopyToClipBoard>
            </InputAdornment>
          )}
        />
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Copied! Thank you!"
        />
      </CardContent>
    </Card>
  );
}

export default Donation;
