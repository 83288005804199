import React, { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  DocumentData,
} from 'firebase/firestore';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { calculatePrice, Currencies } from '../models/currencies';
import { db } from '../components/firebase';

const MaxLevel = 30;
const MinLevel = 0;

function LevelUpCostCard({
  currencies,
  baseCurrency,
  chain,
} : {
  currencies: Currencies;
  baseCurrency: 'JPY' | 'USD';
  chain: 'sol' | 'bnb';
}) {
  const baseGst = chain === 'sol' ? 'GST' : 'bGST';
  const { t } = useTranslation();
  const [fromLevel, setFromLevel] = useState('');
  const [toLevel, setToLevel] = useState('');
  const [cost, setCost] = useState<DocumentData[]>();
  const [calcedCost, setCalcedCost] = useState({
    time: 0, gst: 0, gmt: 0, baseCurrencyAmmount: 0,
  });
  const setFromLevelWrapper = (level: string) => {
    const levelNum = Number(level);
    if (levelNum > MaxLevel) {
      setFromLevel(String(MaxLevel));
    } else if (levelNum < MinLevel) {
      setFromLevel(String(MinLevel));
    } else {
      setFromLevel(level);
    }
  };
  const setToLevelWrapper = (level: string) => {
    const levelNum = Number(level);
    if (levelNum > MaxLevel) {
      setToLevel(String(MaxLevel));
    } else if (levelNum < MinLevel) {
      setToLevel(String(MinLevel));
    } else {
      setToLevel(level);
    }
  };
  useEffect(() => {
    // fetch data
    const f = async () => {
      const ref = collection(db, 'levelUpCosts');
      const snapshot = await getDocs(query(ref));
      const data = snapshot.docs.map((doc) => doc.data());
      setCost(data);
    };
    f();
  }, []);
  useEffect(() => {
    // set calcedCost
    if (cost === undefined) {
      return;
    }
    if (fromLevel === '' || toLevel === '') {
      return;
    }
    if (Number(fromLevel) >= Number(toLevel)) {
      return;
    }
    const targetCost = cost.filter((c) => c.from >= Number(fromLevel))
      .filter((c) => c.to <= Number(toLevel));
    if (targetCost.length === 0) {
      return;
    }
    const data = targetCost.reduce((acc, c) => {
      const d = {
        time: acc.time + c.time,
        gst: acc.gst + c.gst,
        gmt: (acc.gmt || 0) + (c.gmt || 0),
      };
      return d;
    }, { time: 0, gst: 0, gmt: 0 });
    data.baseCurrencyAmmount = calculatePrice(data.gst, baseGst, currencies, baseCurrency)
      + calculatePrice(data.gmt, 'GMT', currencies, baseCurrency);
    setCalcedCost(data as any);
  }, [cost, fromLevel, toLevel, baseCurrency]);
  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h6">
          {t('title.levelUpCost')}
        </Typography>
        <TextField
          size="small"
          fullWidth
          type="number"
          value={fromLevel}
          onChange={(e) => setFromLevelWrapper(e.target.value)}
          placeholder="From Level"
          sx={{ marginBottom: '1rem' }}
        />
        <TextField
          size="small"
          fullWidth
          type="number"
          value={toLevel}
          onChange={(e) => setToLevelWrapper(e.target.value)}
          placeholder="To Level"
        />
        <Typography component="div" variant="subtitle1">
          {(fromLevel || toLevel) && `Level ${fromLevel} → ${toLevel}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">{baseGst}</TableCell>
              <TableCell align="right">GMT</TableCell>
              <TableCell align="right">{baseCurrency}</TableCell>
              <TableCell align="right">{`${t('elements.time')}(m)`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">{calcedCost.gst}</TableCell>
              <TableCell align="right">{calcedCost.gmt}</TableCell>
              <TableCell align="right">{calcedCost.baseCurrencyAmmount}</TableCell>
              <TableCell align="right">{calcedCost.time}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default LevelUpCostCard;
